import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
//reducer
import { IndexRouters } from "./router";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Home from "./views/modules/Event/Home/Home";
import DefaultLayout from "./views/modules/Event/layouts/default-layout";
import { LandingModulesRouter } from "./views/modules/Event/CutomRoutes/landing-modules-router";
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <DefaultLayout header2="true"/>,
      children: [
        {
          path: "",
          element: <Home />,
        }
      ]
    },
    ...IndexRouters,
    ...LandingModulesRouter
  ],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App>
          <RouterProvider router={router} />
        </App>
      </PersistGate>
    </Provider>
  </>
);

